import React, { useState, useEffect } from 'react';

import Layout from '../../../components/layout';
import Content from '../../../components/licensed-in/content-container';

export default () => {
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };

  return (
    <Layout {...props} activePage='licensed-in' id='licensed-in-page'>
      <div className='licensed-in-page-container'>
        <Content title='SECURE INSURANCE IN VIRGINIA' activeCard='virginia' image={'/Virginia_BG.jpeg'}>
          <p>
            Interested in insurance in Virginia? Apollo Insurance can provide affordable solutions for our clients in Virginia. As consumers ourselves, we know
            the stress of witnessing rates increase on premiums this year. We listen to your needs and make sure you receive affordable insurance in Virginia.
          </p>

          <p>Health insurance quote in Kansas Contact us today with questions about affordable healthcare in the state of Virginia.</p>

          <p>
            Since 2010, we have watched our company blaze a path through the thick of this volatile industry by providing our clients with custom built
            coverage. Our clients value what we do for them, and often refer us to their friends and relatives. To Apollo, that says our clients trust us and
            know we truly care about their healthcare needs. We didn’t want to be another healthcare brokerage, we wanted to show our clients we are their eyes
            and ears in this volatile healthcare industry. We pride ourselves in ensuring we help keep our clients money in their pocket instead of giving it to
            the insurance company. Contact us today and become our client. At Apollo, we treat all of our clients just like family.
          </p>

          <p>
            We offer individual, family, disability, vision, dental, accident, critical illness, and gap insurance plans. With the increase in deductibles and
            out of pocket expenses of health insurance shifting to the consumer, gap insurance plans have become the most important and popular plan we sell
            today.
          </p>
        </Content>
      </div>
    </Layout>
  );
};
